import { checkoutPage } from "./checkout.js";
import { header } from "./header.js";
import { availableMenus } from "./availableMenus.js";
import { availableMenuProduct } from "./availableMenuProduct.js";
import { availableMenuAccount } from "./availableMenuAccount.js";
import { accountProfile } from "./accountProfile.js";
import { products } from "./products.js";
import { productSearchBar } from "./productSearchBar.js";
import { configurationProduct } from "./configurationProduct.js";
import { coupons } from "./coupons.js";
import { trackingCode } from "./trackingCode.js";
import { offers } from "./offers.js";

import { documents } from "./documents.js";
import { financialData } from "./financialData.js";
import { security } from "./security.js";
import { modal } from "./modal.js";
import { dashboard } from "./dashboard.js";
import { notificationSettings } from "./notificationSettings.js";
import { notification } from "./notification.js";
import { personalData } from "./personalData.js";
import { selectFields } from "./selectFields.js";
import { productHeader } from "./productHeader.js";
import { privacyPolicy } from "./privacyPolicy.js";
import affiliations from "./affiliations.json";

import authentication from "./authentication.json";

import coProduction from "./co-production.json";

import website from "./website.json";

const i18nSpanish = {
    website,
    authentication,
    coProduction,
    checkout: checkoutPage,
    header,
    availableMenus,
    availableMenuProduct,
    availableMenuAccount,
    accountProfile,

    documents,
    financialData,
    security,
    modal,

    products,
    productSearchBar,
    configurationProduct,
    coupons,
    trackingCode,
    offers,
    dashboard,
    notificationSettings,
    notification,

    personalData,
    selectFields,
    productHeader,
    privacyPolicy,
    affiliations,

    labels: {
        required: "requerido",
        active: "Activo",
        inactive: "Inactivo",
    },

    auth: {
        wrongCredentials: "Credenciales incorrectas. Inténtalo de nuevo.",
    },

    termsOfService: {
        title: "Términos del Servicio",
    },

    poweredByRecaptcha: "Impulsado por Google Recaptcha",

    errors: {
        general: "Algo salió mal. Por favor, inténtalo de nuevo.",
    },

    messages: {
        entityUpdated: ":entity fue actualizado",
    },
};

export default i18nSpanish;
