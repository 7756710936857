// Routes.js
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { PublicRoutes, CreatorAreaRoutes, ClassroomRoutes } from "@/data/Routes/index.js";

import { AuthenticatedErrorHandler, GuestErrorHandler } from "@/routes/RouterErrorHandler.jsx";
import { ProtectedRoute } from "@/routes/ProtectedRoute.jsx";

const AppRouterProvider = () => {
    const routeSettings = {
        v7_startTransition: true,
    };

    const protectRoute = route => {
        if (route.protected) {
            route.element = <ProtectedRoute>{route.element}</ProtectedRoute>;
        }

        return route;
    };

    const setupErrorHandler = (route, isGuest) => {
        route.errorElement = isGuest ? <GuestErrorHandler /> : <AuthenticatedErrorHandler />;

        return route;
    };

    const getStudentRoutes = () => {
        if (import.meta.env.VITE_ENABLE_CLASSROOM === "true") return [];

        return ClassroomRoutes.map(route => {
            route = setupErrorHandler(route, false);
            route = protectRoute(route);

            return route;
        });
    };

    const getCreatorRoutes = () =>
        CreatorAreaRoutes.map(route => {
            route = setupErrorHandler(route, false);
            route = protectRoute(route);

            return route;
        });

    const getPublicRoutes = () =>
        PublicRoutes.map(route => {
            route = setupErrorHandler(route, true);
            route = protectRoute(route);

            return route;
        });

    const createRoutes = () => {
        const publicRoutes = getPublicRoutes();
        const creatorRoutes = getCreatorRoutes();
        const studentRoutes = getStudentRoutes();

        return [].concat(publicRoutes, creatorRoutes, studentRoutes);
    };

    const availableRoutes = createBrowserRouter(createRoutes());

    return (
        <RouterProvider
            router={availableRoutes}
            future={routeSettings}
        />
    );
};

export default AppRouterProvider;
