import XTreedCreatorLayout from "@/layouts/XTreedCreatorLayout.jsx";

import { HeaderWithSubtitle } from "@comp/Typography/HeaderWithSubtitle.jsx";
import { CardWrapper } from "@comp/Card/CardWrapper.jsx";

export const AuthenticatedErrorHandler = () => {
    return (
        <XTreedCreatorLayout>
            <div className="flex flex-col justify-center items-center w-full px-5 gap-5 pt-8">
                <CardWrapper className="py-12 gap-5 outline outline-1 outline-red-100 max-w-screen-md mx-auto flex flex-col">
                    <HeaderWithSubtitle
                        titleSize="2xl text-red-600 font-bold"
                        title={"There is something wrong."}
                        subtitle={"We ran into some kind of unknown problem and need to investigate more."}
                    />

                    <p className="leading-tight">
                        You do not need to worry, there is an automatic error handling in place. <br />
                        We have already been notified about to look into this problem.
                    </p>
                </CardWrapper>
            </div>
        </XTreedCreatorLayout>
    );
};

export const GuestErrorHandler = () => {
    return (
        <div>
            <div className="flex flex-col justify-center items-center w-full px-5 gap-5 pt-8">
                <CardWrapper className="py-12 gap-5 outline outline-1 outline-red-100 max-w-screen-md mx-auto flex flex-col">
                    <HeaderWithSubtitle
                        titleSize="2xl text-red-600 font-bold"
                        title={"There is something wrong."}
                        subtitle={"We ran into some kind of unknown problem and need to investigate more."}
                    />

                    <p className="leading-tight">
                        You do not need to worry, there is an automatic error handling in place. <br />
                        We have already been notified about to look into this problem.
                    </p>
                </CardWrapper>
            </div>
        </div>
    );
};
