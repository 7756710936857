import logoExtended from "@assets/logo-extend.svg";
import logoShort from "@assets/logo-icon.svg";

export const XTreedMainLogo = ({ extended = false }) => {
    const showExtended = "scale-100 w-[100%] opacity-1";

    const showShort = "scale-100 w-[100%] opacity-1";

    const hideExtended = "scale-0 w-[0%] opacity-0 !duration-500";

    const hideShort = "scale-0 w-[0%] opacity-0 !duration-500";

    return (
        <div
            className={`flex flex-row flex-nowrap h-28 transition-all duration-700 ${extended ? "py-3 px-5" : "px-0 py-0"}`}
        >
            <img
                src={logoExtended}
                alt="XTreed Logo"
                className={`transition-all duration-1000 origin-left transform-gpu ${extended ? showExtended : hideExtended}`}
            />
            <img
                src={logoShort}
                alt="XTreed Logo"
                className={`transition-all duration-1000 origin-left mx-auto transform-gpu ${extended ? hideShort : showShort}`}
            />
        </div>
    );
};
