import { Suspense } from "react";
import { ToastContainer } from "react-toastify";

import AppRouterProvider from "./routes/AppRouterProvider.js";
import { LoadingPageFallback } from "./LoadingPageFallback";
import { AuthenticationProvider } from "./contexts/AuthenticationContext";

export const XTreedApp = () => {
    return (
        <Suspense fallback={<LoadingPageFallback />}>
            <AuthenticationProvider>
                <AppRouterProvider />
                <ToastContainer />
            </AuthenticationProvider>
        </Suspense>
    );
};
