import { useContext } from "react";
import { useLocalStorage } from "@/hooks/useLocalStorage.js";
import { AuthService } from "@/services/Authentication/AuthService.js";
import { AuthenticationContext } from "@contexts/AuthenticationContext";

export function useAuthentication() {
    let authService = new AuthService();

    const storage = useLocalStorage();

    const { setIsAuthenticated } = useContext(AuthenticationContext);

    async function login(email, password) {
        await authService.getCsrfToken();

        return authService.login(email, password).then(() => setIsAuthenticated(true));
    }

    function logout() {
        return authService.logout().then(() => postLogout());
    }

    function postLogout() {
        setIsAuthenticated(false);
        storage.cleanAll();
    }

    return {
        login,
        logout,
        postLogout,
    };
}
