import { AuthService } from "@/services/Authentication/AuthService.js";
import { useLocalStorage } from "@/hooks/useLocalStorage.js";
import { User } from "@/models/UserModel.jsx";

export function useUserProfile() {
    const authService = new AuthService();

    const STORAGE_KEY = "xtreed.userProfile";

    const storage = useLocalStorage();

    /**
     * Fetches User Profile from remote server, calling AuthService.getUserData
     * and saves User object returner from promise on Local Storage, using
     * STORAGE_KEY
     * @returns {Promise<void>}
     */
    function fetchUserProfile() {
        return authService
            .getUserData()
            .catch(error => console.error(`Error when fetching user data from server \n ${error.message}`))
            .then(user => toLocalStorage(user));
    }

    /**
     * Put ours User object into Local Storage using STORAGE_KEY
     * @param user
     */
    function toLocalStorage(user) {
        storage.put(STORAGE_KEY, {
            ...user,
            expiration: Date.now() + 1000 * 7200, // Now plus 120 minutes
        });
    }

    /**
     * Get User Profile from Local Storage, after login or updating
     * the key correctly
     * @returns {User} an User instance of Local Storage
     */
    function fromLocalStorage() {
        return new User(storage.get(STORAGE_KEY));
    }

    return {
        fetchUserProfile,
        fromLocalStorage,
    };
}
