import { checkoutPage } from "./checkout.js";
import { header } from "./header.js";
import { availableMenus } from "./availableMenus.js";
import { availableMenuProduct } from "./availableMenuProduct.js";
import { availableMenuAccount } from "./availableMenuAccount.js";
import { accountProfile } from "./accountProfile.js";
import { products } from "./products.js";
import { productSearchBar } from "./productSearchBar.js";
import { configurationProduct } from "./configurationProduct.js";
import { coupons } from "./coupons.js";
import { trackingCode } from "./trackingCode.js";
import { offers } from "./offers.js";

import { documents } from "./documents.js";
import { financialData } from "./financialData.js";
import { security } from "./security.js";
import { modal } from "./modal.js";
import { dashboard } from "./dashboard.js";
import { notificationSettings } from "./notificationSettings.js";
import { notification } from "./notification.js";
import { personalData } from "./personalData.js";
import { selectFields } from "./selectFields.js";
import { productHeader } from "./productHeader.js";
import {privacyPolicy} from "./privacyPolicy.js";
import affiliations from "./affiliations.json";

import authentication from "./authentication.json";

import coProduction from "./co-production.json";

const i18nPortuguese = {
  authentication,
  checkout: checkoutPage,
  header,
  availableMenus,
  availableMenuProduct,
  availableMenuAccount,
  accountProfile,
  coProduction,

  documents,
  financialData,
  security,
  modal,

  products,
  productSearchBar,
  configurationProduct,
  coupons,
  trackingCode,
  offers,
  dashboard,
  notificationSettings,
  notification,

  personalData,
  selectFields,
  productHeader,
  privacyPolicy,
  affiliations,

  labels: {
    required: "obrigatório",
    active: "Ativo",
    inactive: "Inativo",
  },

  auth: {
    wrongCredentials: "Credenciais incorretas. Tente novamente."
  },

  privacyPolicy: {
    title: "Política de Privacidade"
  },

  termsOfService: {
    title: "Termos de Serviço"
  },

  poweredByRecaptcha: "Desenvolvido pelo Google Recaptcha",

  errors: {
    general: "Algo deu errado. Por favor, tente novamente.",
  },

  messages: {
    entityUpdated: ":entity foi atualizado",
  },
};

export default i18nPortuguese;
