import i18n from "i18next";

const TextField = ({
    label = undefined,
    required = false,
    type = "text",
    placeholder = "",
    name = "",
    id = undefined,
    value = undefined,
    onChange = undefined,
    onKeyDown = undefined,
    error = undefined,
    readOnly = false,
    disabled = false,
    leadingIcon = {
        image: undefined,
        callback: undefined,
    },
    trailingIcon = {
        alt: "",
        image: undefined,
        callback: undefined,
    },
    inputClass = "",
    autoFocus = false,
}) => {
    return (
        <div className="flex flex-col gap-1 w-full">
            {label && (
                <label
                    htmlFor={id}
                    className="flex flex-row items-baseline justify-start gap-1 text-left text-sm"
                >
                    {label}
                    {required && (
                        <span className={"text-red-400 bg-red-100/30 rounded-md px-2 py-1  text-xs"}>
                            {i18n.t("labels.required")}
                        </span>
                    )}
                </label>
            )}

            <div
                className={`
          flex w-full items-center overflow-clip rounded-lg bg-white border border-gray-300 
          transition duration-500 focus-within:ring-2 focus-within:border-transparent focus-within:ring-green-500 focus-within:shadow-md
          ${error ? "focus-within:border-red-500" : "focus-within:border-green-500"}
        `}
            >
                {leadingIcon.image && (
                    <img
                        className="ms-2 w-5 h-5"
                        alt=""
                        onClick={leadingIcon.callback}
                        src={leadingIcon.image}
                    />
                )}

                <input
                    id={id}
                    autoFocus={autoFocus}
                    type={type}
                    name={name}
                    required={required}
                    className={`border-none outline-none w-full py-2 pl-3 disabled:text-gray-400 read-only:text-gray-500 read-only:cursor-not-allowed ${inputClass}`}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    readOnly={readOnly}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                />

                {trailingIcon.image && (
                    <img
                        className="w-5 h-5 cursor-pointer transition-all duration-250"
                        title={trailingIcon.alt}
                        alt={trailingIcon.alt}
                        onClick={trailingIcon.callback}
                        src={trailingIcon.image}
                    />
                )}
            </div>

            {error && <div className="text-red-500 text-sm">{error}</div>}
        </div>
    );
};

export default TextField;
