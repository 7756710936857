import { HomeOutline } from "@assets/Icons/HomeOutlineIcon.jsx";
import { UserIconDash } from "@assets/Icons/UserIconDash.jsx";
import { SettingsIcon } from "@assets/Icons/SettingIcon.jsx";
import { ShoppingIcon } from "@assets/Icons/ShoppingIcon.jsx";
import { ShoppingCartIcon } from "@assets/Icons/ShoppingCartIcon.jsx";
import { BriefcaseIcon } from "@assets/Icons/BriefcaseIcon.jsx";
import { DollarSign } from "@assets/Icons/DolarSignIcon.jsx";
import { ChartIcon } from "@assets/Icons/ChartIcon.jsx";
import { ChartMarketingIcon } from "@assets/Icons/ChartMarketingIcon.jsx";

export const StudentMainMenu = [
    {
        id: 0,
        icon: <HomeOutline />,
        name: "Dashboard",
        target: "/dashboard",
    },
    {
        id: 1,
        icon: <UserIconDash />,
        name: "Usuário",
        target: "/areaUser",
    },
    {
        id: 2,
        icon: <SettingsIcon />,
        name: "Configuração",
        target: "/configuration",
    },
    {
        id: 3,
        icon: <ShoppingIcon />,
        name: "Produtos",
        target: "/myproducts",
    },
];

export const CreatorUserMenu = [
    {
        id: 0,
        icon: <HomeOutline />,
        name: "availableMenus.dashboard",
        target: "/dashboard",
    },
    {
        id: 1,
        icon: <ShoppingCartIcon />,
        name: "availableMenus.sales",
        target: "/sales",
    },
    {
        id: 2,
        icon: <BriefcaseIcon />,
        name: "availableMenus.products",
        target: "/products",
    },
    {
        id: 3,
        icon: <DollarSign />,
        name: "availableMenus.financial",
        target: "/financial",
    },
    {
        id: 4,
        icon: <ChartIcon />,
        name: "availableMenus.reports",
        target: "/reports",
    },
    {
        id: 5,
        icon: <ChartMarketingIcon />,
        name: "availableMenus.marketing",
        target: "/marketing",
    },
];

export const WebsiteMenu = [
    { label: "Inicio", target: "/" },
    { label: "Recursos", target: "#resources" },
];
